import React from 'react'

const AbcSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <mask id="rippleMask">
        <path
          className="ripple"
          d="M182.85,570.82c-69.67-21-160.69-77.71-174.9-98.7S67,432.56,83.07,420s1.86-43.33,15.27-65.74,75.69-15.42,93.52-43.4,3.42-47.17,19-75.17c21-37.78,78.67,11.45,89.17-41.9,8.46-43-8.8-69.44-24.71-101C264.8,72,285.09,56,335.22,70.26,374.34,81.35,360,9.6,402,11.7c56,2.79,143.39,86.93,156.72,111.93,23.78,44.63,40.57,121.07,33.58,200.09-3.61,40.8-14.56,134.17-49,177.7C448.15,621.75,225.51,589,182.85,570.82"
          fill="white"
        />
      </mask>

      {/* Always Be Closing text */}
      <g mask="url(#rippleMask)">
        <path
          className="colorText"
          d="M9.86,29.38l-.24-1s0,0-.06,0h-2s0,0,0,0l-.24,1a.15.15,0,0,1-.15.1H5.85c-.08,0-.12,0-.1-.13l1.91-7.18a.13.13,0,0,1,.14-.1H9.34a.14.14,0,0,1,.15.1l1.92,7.18c0,.09,0,.13-.11.13H10A.12.12,0,0,1,9.86,29.38Zm-2-2.24h1.4a0,0,0,0,0,.05-.06l-.71-3c0-.05,0,0-.06,0l-.73,3A0,0,0,0,0,7.88,27.14Z"
        />
        <path
          className="whiteText"
          d="M12.46,29.36V22.19a.12.12,0,0,1,.12-.12h1.24a.12.12,0,0,1,.13.12v6a0,0,0,0,0,.05.05h2.84a.13.13,0,0,1,.13.13v1a.12.12,0,0,1-.13.12H12.58A.12.12,0,0,1,12.46,29.36Z"
        />
        <path
          className="whiteText"
          d="M19,29.38,17.37,22.2c0-.09,0-.13.12-.13h1.3c.08,0,.13,0,.14.11l.81,4.07s.05,0,.06,0l.77-4.07a.12.12,0,0,1,.14-.11h1.18a.13.13,0,0,1,.14.11l.84,4.07a0,0,0,0,0,.07,0l.75-4.07c0-.08.06-.11.14-.11h1.26c.08,0,.13,0,.1.13l-1.54,7.17a.13.13,0,0,1-.14.11h-1.2a.13.13,0,0,1-.14-.11l-.85-4.26a0,0,0,0,0-.07,0l-.78,4.26a.12.12,0,0,1-.14.11H19.17A.12.12,0,0,1,19,29.38Z"
        />
        <path
          className="whiteText"
          d="M29.13,29.38l-.25-1s0,0-.05,0h-2s-.05,0-.06,0l-.24,1a.14.14,0,0,1-.15.1H25.12c-.09,0-.13,0-.11-.13l1.91-7.18a.14.14,0,0,1,.15-.1h1.54a.13.13,0,0,1,.14.1l1.92,7.18c0,.09,0,.13-.1.13H29.26A.12.12,0,0,1,29.13,29.38Zm-2-2.24h1.4s.07,0,.06-.06l-.71-3c0-.05-.06,0-.07,0l-.73,3A0,0,0,0,0,27.14,27.14Z"
        />
        <path
          className="whiteText"
          d="M32.54,29.35V26.63a.15.15,0,0,0,0-.06l-1.84-4.36c0-.08,0-.14.1-.14h1.33a.14.14,0,0,1,.15.1l1,2.68s.05,0,.06,0l1-2.68a.14.14,0,0,1,.15-.1h1.33c.1,0,.13.06.1.14L34,26.57a.15.15,0,0,0,0,.06v2.72a.12.12,0,0,1-.13.12H32.66A.12.12,0,0,1,32.54,29.35Z"
        />
        <path
          className="whiteText"
          d="M36.48,27.49v-.28a.12.12,0,0,1,.13-.13h1.21a.12.12,0,0,1,.13.13v.21a.9.9,0,0,0,1,.87.75.75,0,0,0,.85-.76c0-.49-.43-.72-1.3-1.16s-2-1-2-2.35a2.06,2.06,0,0,1,2.31-2,2.19,2.19,0,0,1,2.43,2.15v.21a.13.13,0,0,1-.13.13H39.88a.13.13,0,0,1-.13-.13v-.19a.9.9,0,0,0-1-.89A.72.72,0,0,0,38,24c0,.49.36.72,1.32,1.19s2,1,2,2.27a2.12,2.12,0,0,1-2.4,2.1A2.13,2.13,0,0,1,36.48,27.49Z"
        />
        <path
          className="colorText"
          d="M47.21,29.48H44.84a.12.12,0,0,1-.13-.12V22.19a.12.12,0,0,1,.13-.12h2.21c1.47,0,2.39.62,2.39,2a1.61,1.61,0,0,1-.82,1.52s0,0,0,.06a1.85,1.85,0,0,1,.93,1.73C49.56,28.82,48.53,29.48,47.21,29.48Zm-1-6.08v1.67a0,0,0,0,0,0,.05h.8a.89.89,0,1,0,0-1.77h-.8A0,0,0,0,0,46.21,23.4Zm1.84,3.84a.87.87,0,0,0-.9-1h-.89s0,0,0,.06v1.82a0,0,0,0,0,0,.05h.89A.85.85,0,0,0,48.05,27.24Z"
        />
        <path
          className="whiteText"
          d="M55.08,23.35H52.24s-.06,0-.06,0v1.65a.05.05,0,0,0,.06.06H54a.12.12,0,0,1,.12.12v1a.12.12,0,0,1-.12.13H52.24s-.06,0-.06.05v1.71s0,.05.06.05h2.84a.12.12,0,0,1,.12.13v1a.12.12,0,0,1-.12.12H50.82a.12.12,0,0,1-.13-.12V22.19a.12.12,0,0,1,.13-.12h4.26a.12.12,0,0,1,.12.12v1A.12.12,0,0,1,55.08,23.35Z"
        />
        <path
          className="colorText"
          d="M58.52,27.24V24.29A2.23,2.23,0,0,1,61,22a2.2,2.2,0,0,1,2.42,2.26v.05a.13.13,0,0,1-.12.14L62,24.48c-.09,0-.13,0-.13-.11v-.14a.94.94,0,1,0-1.87,0v3.1a.94.94,0,1,0,1.87,0V27.2c0-.09,0-.12.13-.12l1.24.05c.07,0,.12,0,.12.08v.08A2.21,2.21,0,0,1,61,29.57,2.24,2.24,0,0,1,58.52,27.24Z"
        />
        <path
          className="whiteText"
          d="M64.54,29.36V22.19a.12.12,0,0,1,.12-.12h1.25a.12.12,0,0,1,.12.12v6s0,.05.06.05h2.84a.12.12,0,0,1,.12.13v1a.12.12,0,0,1-.12.12H64.66A.12.12,0,0,1,64.54,29.36Z"
        />
        <path
          className="whiteText"
          d="M69.85,27.18V24.37a2.46,2.46,0,0,1,4.92,0v2.81a2.46,2.46,0,0,1-4.92,0Zm3.43.07V24.32a1,1,0,1,0-1.94,0v2.93a1,1,0,1,0,1.94,0Z"
        />
        <path
          className="whiteText"
          d="M75.8,27.49v-.28a.12.12,0,0,1,.13-.13h1.22a.12.12,0,0,1,.12.13v.21a.91.91,0,0,0,1,.87.75.75,0,0,0,.85-.76c0-.49-.43-.72-1.29-1.16s-2-1-2-2.35a2.07,2.07,0,0,1,2.31-2,2.19,2.19,0,0,1,2.43,2.15v.21a.12.12,0,0,1-.13.13H79.2a.12.12,0,0,1-.12-.13v-.19a.9.9,0,0,0-1-.89.72.72,0,0,0-.81.75c0,.49.36.72,1.31,1.19s2,1,2,2.27a2.42,2.42,0,0,1-4.8,0Z"
        />
        <path
          className="whiteText"
          d="M81.7,29.36V22.19a.12.12,0,0,1,.13-.12h1.24a.12.12,0,0,1,.13.12v7.17a.12.12,0,0,1-.13.12H81.83A.12.12,0,0,1,81.7,29.36Z"
        />
        <path
          className="whiteText"
          d="M84.52,29.36V22.19a.12.12,0,0,1,.13-.12H85.9a.16.16,0,0,1,.15.09l2,4.23s.07,0,.07,0V22.19a.12.12,0,0,1,.12-.12h1.23a.12.12,0,0,1,.13.12v7.17a.12.12,0,0,1-.13.12H88.23a.18.18,0,0,1-.15-.09l-2-4.26s-.07,0-.07,0l0,4.21a.12.12,0,0,1-.13.12H84.65A.12.12,0,0,1,84.52,29.36Z"
        />
        <path
          className="whiteText"
          d="M90.82,27.26v-3a2.43,2.43,0,0,1,4.85-.09v.23a.13.13,0,0,1-.13.13H94.3a.12.12,0,0,1-.12-.13V24.2a.9.9,0,0,0-.94-.94.93.93,0,0,0-.94,1v3a1,1,0,0,0,1,1,.85.85,0,0,0,.91-.91v-.66s0,0-.06,0h-.82a.13.13,0,0,1-.13-.13v-1a.12.12,0,0,1,.13-.12h2.23a.12.12,0,0,1,.13.12v1.69a2.43,2.43,0,0,1-4.85,0Z"
        />
        <path
          className="grayText"
          d="M25.82,75.15l-1.27-5.43c-.06-.17-.11-.22-.28-.22H13.9c-.17,0-.22,0-.28.22l-1.27,5.43a.73.73,0,0,1-.78.56H4.86c-.44,0-.67-.22-.56-.72l10-37.55a.73.73,0,0,1,.77-.56h8a.72.72,0,0,1,.77.56L33.92,75c.11.5-.11.72-.55.72H26.55A.68.68,0,0,1,25.82,75.15ZM15.45,63.45h7.32c.17,0,.34-.11.28-.33L19.34,47.37c-.06-.28-.28-.22-.34,0L15.18,63.12A.25.25,0,0,0,15.45,63.45Z"
        />
        <path
          className="grayText"
          d="M52.5,75.71H40.08a.65.65,0,0,1-.67-.67V37.55a.65.65,0,0,1,.67-.67H51.67c7.66,0,12.48,3.28,12.48,10.65,0,3.77-1.44,6.33-4.27,7.93-.17.12-.22.23-.06.34,3.11,1.77,4.83,4.71,4.89,9C64.76,72.21,59.38,75.71,52.5,75.71ZM47.23,43.87v8.71a.27.27,0,0,0,.28.28h4.22c3,0,4.77-1.72,4.77-4.61s-1.78-4.65-4.77-4.65H47.51A.27.27,0,0,0,47.23,43.87ZM56.88,64c0-3.16-1.77-5.1-4.71-5.1H47.51a.27.27,0,0,0-.28.28v9.53a.27.27,0,0,0,.28.28h4.66C55.11,68.94,56.88,67.17,56.88,64Z"
        />
        <path
          className="grayText"
          d="M70.09,64V48.53c0-7.32,5-12.09,12.7-12.09s12.7,4.66,12.7,11.81v.28a.69.69,0,0,1-.67.72l-6.49.28c-.44,0-.66-.17-.66-.61V48.2a4.88,4.88,0,1,0-9.76,0V64.45a4.88,4.88,0,1,0,9.76,0v-.72c0-.45.22-.61.66-.61l6.49.28c.39,0,.67.11.67.38v.45c0,7.21-5,11.92-12.7,11.92S70.09,71.33,70.09,64Z"
        />
      </g>

      {/* Stroke */}
      <g>
        <path
          className="stroke"
          strokeDasharray="169.1885986328125"
          strokeDashoffset="169.1885986328125"
          d="M25.62,75.15l-1.28-5.43c-.06-.17-.11-.22-.28-.22H13.69c-.17,0-.22,0-.28.22l-1.27,5.43a.73.73,0,0,1-.78.56H4.65c-.44,0-.66-.22-.55-.72l10-37.55a.73.73,0,0,1,.78-.56h8a.72.72,0,0,1,.77.56L33.71,75c.11.5-.11.72-.55.72H26.34A.67.67,0,0,1,25.62,75.15ZM15.24,63.45h7.33a.25.25,0,0,0,.27-.33L19.13,47.37c-.06-.28-.28-.22-.34,0L15,63.12A.25.25,0,0,0,15.24,63.45Z"
        />
        <path
          className="stroke"
          strokeDasharray="190.09629821777344"
          strokeDashoffset="190.09629821777344"
          d="M52.29,75.71H39.87A.65.65,0,0,1,39.2,75V37.55a.65.65,0,0,1,.67-.67H51.46c7.66,0,12.48,3.28,12.48,10.65,0,3.77-1.44,6.33-4.27,7.93-.17.12-.22.23-.05.34,3.1,1.77,4.82,4.71,4.88,9C64.55,72.21,59.17,75.71,52.29,75.71ZM47,43.87v8.71a.26.26,0,0,0,.27.28h4.22c3,0,4.77-1.72,4.77-4.61s-1.78-4.65-4.77-4.65H47.3A.26.26,0,0,0,47,43.87ZM56.68,64c0-3.16-1.78-5.1-4.72-5.1H47.3a.26.26,0,0,0-.27.28v9.53a.26.26,0,0,0,.27.28H52C54.9,68.94,56.68,67.17,56.68,64Z"
        />
        <path
          className="stroke"
          strokeDasharray="160.37400817871094"
          strokeDashoffset="160.37400817871094"
          d="M69.88,64V48.53c0-7.32,5-12.09,12.7-12.09s12.7,4.66,12.7,11.81v.28a.69.69,0,0,1-.67.72l-6.49.28c-.44,0-.66-.17-.66-.61V48.2a4.88,4.88,0,1,0-9.76,0V64.45a4.88,4.88,0,1,0,9.76,0v-.72c0-.45.22-.61.66-.61l6.49.28c.39,0,.67.11.67.38v.45c0,7.21-5,11.92-12.7,11.92S69.88,71.33,69.88,64Z"
        />
      </g>
    </svg>
  )
}

export default AbcSVG
