import React from 'react'
import Layout from '../../components/Demo/Layout'
import ABC from '../../components/2021/ABC/ABC'

const AbcPage = () => {
  const pageSlug = 'abc'

  return (
    <Layout pageSlug={pageSlug}>
      <ABC />
    </Layout>
  )
}

export default AbcPage
