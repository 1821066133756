import React, { useEffect } from 'react'
import gsap from 'gsap'

import AbcSVG from './parts/AbcSVG'

import styled from 'styled-components'

const DemoWrapper = styled.div`
  background-color: #282727;
  height: 100vh;
  display: grid;
  place-items: center;
  .draw-box {
    overflow: hidden;
    position: relative;
    width: 400px;
    svg {
      width: 100%;
      .stroke {
        fill: none;
        stroke: #c5c5c5;
        stroke-width: 0.5;
      }
      .colorText {
        fill: #3898b1;
      }
      .whiteText {
        fill: #d8d8d8;
      }
      .grayText {
        fill: #2f2f2f;
      }
    }
  }
`

const ABC = () => {
  useEffect(() => {
    // const welcome = document.querySelectorAll('.stroke')
    // for (let i = 0; i < welcome.length; i++) {
    //   console.log(`Letter ${i} is ${welcome[i].getTotalLength()}`)
    // }

    const tl = gsap.timeline()

    tl.to('.stroke', {
      duration: 2,
      strokeDashoffset: 0,
      stagger: {
        each: 0.1,
      },
    }).to('.ripple', {
      duration: 3,
      delay: -1.2,
      xPercent: -50,
      yPercent: -40,
    })
  })

  return (
    <DemoWrapper>
      <div className="draw-box">
        <AbcSVG />
      </div>
    </DemoWrapper>
  )
}

export default ABC
